import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

type YoutubeIconProps = {
  className?: string
  width?: string
  height?: string
  fill?: string
}

export default chakra(function YoutubeIcon({
  className,
  width,
  height,
  fill
}: YoutubeIconProps) {
  return (
    <Icon className={className} viewBox="0 0 21 15" width={width || '163'} height={height || '18'} fill={fill || 'pdftron'}>
      <title>Youtube Logo</title>
      <path
        d="M20.5933 2.20398C20.3633 1.34598 19.6883 0.668984 18.8313 0.437984C17.2653 0.00798395 11.0003 0.000984159 11.0003 0.000984159C11.0003 0.000984159 4.73633 -0.00601605 3.16933 0.404984C2.32933 0.633984 1.63533 1.32598 1.40333 2.18298C0.99033 3.74898 0.98633 6.99698 0.98633 6.99698C0.98633 6.99698 0.98233 10.261 1.39233 11.811C1.62233 12.668 2.29733 13.345 3.15533 13.576C4.73733 14.006 10.9853 14.013 10.9853 14.013C10.9853 14.013 17.2503 14.02 18.8163 13.61C19.6723 13.38 20.3503 12.704 20.5833 11.847C20.9973 10.282 21.0003 7.03498 21.0003 7.03498C21.0003 7.03498 21.0203 3.76998 20.5933 2.20398ZM8.99633 10.006L9.00133 4.00598L14.2083 7.01098L8.99633 10.006Z"
      />
    </Icon> 
  )
})

