import { useDisclosure } from '@chakra-ui/hooks';
import { createContext, useContext, useState } from 'react';
import { SearchPopupProps } from './SearchPopup';
import React from 'react';

const SearchPopupContext = createContext<SearchPopupContext>({});

export const useSearchPopup = () => {
  return useContext(SearchPopupContext);
};

export const SearchPopupProvider = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalProps, setModalProps] = useState({});

  return (
    <SearchPopupContext.Provider
      value={{ isOpen, onOpen, onClose, modalProps, setModalProps }}
    >
      {props.children}
    </SearchPopupContext.Provider>
  );
};

type SearchPopupContext = {
  isOpen?: boolean;
  onOpen?(): void;
  onClose?(): void;
  modalProps?: SearchPopupProps;
  setModalProps?(args1: SearchPopupProps): void;
};
