import React, { useEffect, useRef, useState } from 'react';
import { DropdownOption } from './DocumentationHeader/DocumentationHeader';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import Link from './Link';
import ExternalLinkIcon from '../icons/ExternalLinkIcon';
import Dropdown from './Dropdown';

interface ResponsiveTabProps {
  items: DropdownOption[];
  selectedDocumentation: DropdownOption;
}

interface WidthMap {
  [key: number]: number;
}

const ResponsiveButtonTabs: React.FC<ResponsiveTabProps> = ({
  items,
  selectedDocumentation,
}) => {
  const [visibleItems, setVisibleItems] = useState<DropdownOption[]>(items);
  const [dropdownItems, setDropdownItems] = useState<DropdownOption[]>([]);
  const dropDownIconWidth = 40
  const defaultButtonWidth = 250
  const tabContainerRef = useRef<HTMLDivElement | null>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const widthMap = useRef<WidthMap>({});

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, items.length);
    const calculateVisibleAndDropdownItems = () => {
      if (!tabContainerRef.current) return { newVisibleItems: [], newDropdownItems: [] };
      const containerWidth = tabContainerRef.current.offsetWidth;
      const dropdownWidth = dropdownRef.current?.offsetWidth;
      let currentWidth = 0;
      const newVisibleItems: DropdownOption[] = [];
      const newDropdownItems: DropdownOption[] = [];
      for (let i = 0; i < items.length; i++) {
        const index = i;
        const item = items[index];
        const itemRef = itemRefs.current[index];
        const newDropDownWidth = dropdownRef.current ? dropdownWidth : dropDownIconWidth;
        if (!widthMap.current[index] && itemRef) {
          widthMap.current[index] = itemRef.offsetWidth;
        }
        const itemWidth = itemRef ? itemRef.offsetWidth : widthMap.current[index] || defaultButtonWidth;
        if (currentWidth + newDropDownWidth + itemWidth < containerWidth) {
          newVisibleItems.push(item);
          currentWidth += itemWidth;
        } else {
          newDropdownItems.push(...items.slice(index));
          break;
        }
      }
      return { newVisibleItems, newDropdownItems };
    };
    const handleResize = () => {
      const { newVisibleItems, newDropdownItems } = calculateVisibleAndDropdownItems();
      setVisibleItems(newVisibleItems);
      setDropdownItems(newDropdownItems);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [items]);

  return (
    <>
      <Flex
        h='100%'
        alignItems='center'
        className='responsive-tab'
        ref={tabContainerRef}
        w='100%'
        display={{ base: 'none', sm: 'flex' }}
        overflow='hidden'
      >
        <Flex h='100%' alignItems='center' className='button-list'>
          {visibleItems.map((item, index) => {
            if (item.title === selectedDocumentation?.title) {
              return (
                <div ref={(el) => (itemRefs.current[index] = el)} key={index}>
                  <Button variant='softBlue' padding='8px 16px'>
                    <Link to={item.link} blue>
                      <Text color='inherit' whiteSpace='nowrap'>
                        {item.title}
                      </Text>
                    </Link>
                    {item.isExternal && <ExternalLinkIcon ml='10px' />}
                  </Button>
                </div>
              );
            } else {
              return (
                <div ref={(el) => (itemRefs.current[index] = el)} key={index}>
                  <Link padding='8px 16px' to={item.link} fontWeight='500'>
                    <Flex
                      alignItems='center'
                      color='#00083D'
                      fill='apryseNavy'
                      _hover={{ color: 'apryseIndigo', fill: 'apryseIndigo' }}
                      w='100%'
                    >
                      <Text color='inherit' whiteSpace='nowrap'>
                        {item.title}
                      </Text>
                      {item.isExternal && (
                        <ExternalLinkIcon ml='8px' fill='inherit' />
                      )}
                    </Flex>
                  </Link>
                </div>
              );
            }
          })}
        </Flex>
        {dropdownItems.length > 0 && (
          <Box className='dropdown' ref={dropdownRef} pl='16px'>
            <Dropdown options={dropdownItems} onSelect={() => {}} />
          </Box>
        )}
      </Flex>
      <Flex
        minW={{ base: '0', sm: '224px', lg: '230px' }}
        w='100%'
        justifyContent={'flex-start'}
        border='1px solid #CED4DA'
        borderRadius='4px'
        p='4px 10px'
        display={{ base: 'flex', sm: 'none' }}
        cursor='pointer'
      >
        <Dropdown
          options={items}
          onSelect={() => {}}
          selected={selectedDocumentation?.title || items[0].title}
        />
      </Flex>
    </>
  );
};

export default ResponsiveButtonTabs;
