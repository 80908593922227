let lastPosition = 0;

export default function applyPolyfills() {
  if (typeof window !== "undefined") {
    window.setBodyScroll = (b) => {
      if (window.innerWidth > 900) {
        document.body.className = "";
        return;
      }
      if (b) {
        lastPosition =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0;
        document.body.className = "modal-open";
      } else {
        document.body.className = "";
        setWindowScroll(lastPosition);
      }
    };
  
    window.preventScroll = (b) => {
      if (b) {
        document.body.className = "prevent-scroll";
      } else {
        document.body.className = "";
      }
    };
  
    if (window.NodeList && !NodeList.prototype.forEach) {
      // @ts-ignore
      NodeList.prototype.forEach = Array.prototype.forEach;
    }
  
    // @ts-ignore
    if (window.NodeList && !NodeList.prototype.map) {
      // @ts-ignore
      NodeList.prototype.map = Array.prototype.map;
    }
  
    // @ts-ignore
    if (window.NodeList && !NodeList.prototype.reduce) {
      // @ts-ignore
      NodeList.prototype.reduce = Array.prototype.reduce;
    }
  }
}

export const setWindowScroll = (value) => {
  document.documentElement.scrollTop = value;
  document.body.scrollTop = value;
};

