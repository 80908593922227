export const isProd = () => {
  if(typeof process === 'object' && process + '' === '[object process]'){
    return false;
  }
  const { origin } = window.location;
  if (origin.indexOf('staging') > -1 || origin.indexOf('build') > -1) return false; 

  const p = origin.indexOf('apryse.com') > -1;
  return p;
}

export const isLocalHost = () => { 
  if(typeof process === 'object' && process + '' === '[object process]'){
    return false;
  }
  const { origin } = window.location;
  const p = origin.indexOf('localhost') > -1;

  return p; 
}

export const isLocal = !isProd();

const key = '@pdftron_downloads_session';
export const fireScrollDownloadEvent = (label, platform) => {

  const sessionData = JSON.parse(sessionStorage.getItem(key) || '{}');

  if (sessionData[platform]) {

    if (sessionData[platform][label]) {
      return;
    }

    sessionData[platform][label] = true;
  } else {
    sessionData[platform] = {
      [label]: true
    }
  }

  sessionStorage.setItem(key, JSON.stringify(sessionData));

}

const VALIDATION_KEY = '@pdftron_downloaded_packages'
const validateDownloadEvent = (location) => {
  const existingDownloadsString = localStorage.getItem(VALIDATION_KEY) || '[]';

  let existingDownloads = [];
  try {
    existingDownloads = JSON.parse(existingDownloadsString);
  } catch (e) {
    // do nothing
  }

  if (existingDownloads.indexOf(location) > -1) {
    return false;
  }

  try {
    existingDownloads.push(location);
    localStorage.setItem(VALIDATION_KEY, JSON.stringify(existingDownloads));
  } catch (e) {
    // do nothing
  }
  return true;
}

export const linkedInDownloadId = 5408177
export const linkedInConversionId = 5408169
