import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

type TwitterIconProps = {
  className?: string
  width?: string
  height?: string
  fill?: string
}

export default chakra(function TwitterIcon({
  className,
  width,
  height,
  fill
}: TwitterIconProps) {
  return (
    <Icon className={className} viewBox="0 0 20 16" width={width || '163'} height={height || '18'} fill={fill || 'pdftron'}>
      <title>Twitter Logo</title>
      <path
        d="M17.633 3.99802C17.646 4.17302 17.646 4.34702 17.646 4.52102C17.646 9.84602 13.593 15.982 6.186 15.982C3.904 15.982 1.784 15.321 0 14.173C0.324 14.21 0.636 14.223 0.973 14.223C2.856 14.223 4.589 13.587 5.974 12.502C4.203 12.465 2.719 11.305 2.207 9.70902C2.456 9.74602 2.706 9.77102 2.968 9.77102C3.329 9.77102 3.692 9.72102 4.029 9.63402C2.182 9.26002 0.799 7.63902 0.799 5.68102V5.63102C1.336 5.93002 1.959 6.11702 2.619 6.14202C1.534 5.42002 0.823 4.18502 0.823 2.78802C0.823 2.04002 1.022 1.35402 1.371 0.75602C3.354 3.19902 6.335 4.79602 9.677 4.97102C9.615 4.67102 9.577 4.36002 9.577 4.04802C9.577 1.82802 11.373 0.0200195 13.605 0.0200195C14.765 0.0200195 15.812 0.506019 16.548 1.29202C17.458 1.11702 18.33 0.780019 19.104 0.319019C18.805 1.25402 18.168 2.04002 17.333 2.53902C18.144 2.45102 18.93 2.22702 19.652 1.91502C19.104 2.71302 18.419 3.42402 17.633 3.99802Z"
      />

    </Icon> 
  )
})
/**
 
 <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.633 3.99802C17.646 4.17302 17.646 4.34702 17.646 4.52102C17.646 9.84602 13.593 15.982 6.186 15.982C3.904 15.982 1.784 15.321 0 14.173C0.324 14.21 0.636 14.223 0.973 14.223C2.856 14.223 4.589 13.587 5.974 12.502C4.203 12.465 2.719 11.305 2.207 9.70902C2.456 9.74602 2.706 9.77102 2.968 9.77102C3.329 9.77102 3.692 9.72102 4.029 9.63402C2.182 9.26002 0.799 7.63902 0.799 5.68102V5.63102C1.336 5.93002 1.959 6.11702 2.619 6.14202C1.534 5.42002 0.823 4.18502 0.823 2.78802C0.823 2.04002 1.022 1.35402 1.371 0.75602C3.354 3.19902 6.335 4.79602 9.677 4.97102C9.615 4.67102 9.577 4.36002 9.577 4.04802C9.577 1.82802 11.373 0.0200195 13.605 0.0200195C14.765 0.0200195 15.812 0.506019 16.548 1.29202C17.458 1.11702 18.33 0.780019 19.104 0.319019C18.805 1.25402 18.168 2.04002 17.333 2.53902C18.144 2.45102 18.93 2.22702 19.652 1.91502C19.104 2.71302 18.419 3.42402 17.633 3.99802Z" fill="#868E96"/>
</svg>

 
 
 */