import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { clearCachedKey } from '../services/PWSService';
import { analyticsLogger } from '../util/logger';

export type IdpHint = 'google' | 'github' | 'microsoft';
export type LoginType = 'email';

const AUTH_SERVER_DOMAIN = process.env.GATSBY_AUTH_SERVER_DOMAIN;
const AUTH_SERVER_REALM = process.env.GATSBY_AUTH_SERVER_REALM;
const AUTH_SESSION_COOKIE_NAME = process.env.GATSBY_AUTH_SESSION_COOKIE_NAME;
const PORTAL_URL = process.env.GATSBY_PORTAL_URL;
const SIGN_OUT_CALLBACK = process.env.GATSBY_SIGN_OUT_CALLBACK;
const WAS_SIGNED_IN_COOKIE_NAME = 'wasSignedIn';

let csrfToken: string | undefined;

type JWTUser = {
  country?: string;
  email?: string;
  name?: string;
  sub?: string;
  identityProvider?: string;
};

export type User = Omit<JWTUser, 'sub'> & { id: string };

type Token = {
  raw: string;
  header: Record<string, any>;
  payload: JWTUser;
};

function jwtDecode(t: string) {
  try {
    const token: Partial<Token> = {};
    token.raw = t;
    token.header = JSON.parse(window.atob(t.split('.')[0]));
    token.payload = JSON.parse(window.atob(t.split('.')[1]));
    return token;
  } catch (e) {
    return null;
  }
}

const getCsrfToken = async () => {
  if (csrfToken) {
    return csrfToken;
  }
  try {
    const response = await fetch(`${PORTAL_URL}/api/auth/csrf`, {
      credentials: 'include',
    });
    if (response.status === 200) {
      const res = await response.json();
      csrfToken = res.csrfToken;
      return csrfToken;
    } else {
      throw new Error(
        `Error during CSRF token retrieval: ${response.statusText}`
      );
    }
  } catch (error) {
    throw new Error(error);
  }
};

const postWithCsrf = async ({
  url,
  callbackUrl,
  idpHint,
  login_type,
}: {
  url: string;
  callbackUrl?: string;
  idpHint?: IdpHint;
  login_type?: LoginType;
}) => {
  const csrfToken = await getCsrfToken();
  const data: { url: string } = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      csrfToken,
      json: 'true',
      ...(idpHint ? { idpHint } : {}),
      ...(login_type ? { login_type } : {}),
      callbackUrl,
    }),
    redirect: 'follow',
    credentials: 'include',
  }).then((res) => res.json());
  return data;
};

export const signIn = async ({
  idpHint,
  login_type,
}: {
  idpHint?: IdpHint;
  login_type?: LoginType;
}) => {
  const url = `${PORTAL_URL}/api/auth/signin/keycloak`;
  const currentUrl = window.location.href;
  const data = await postWithCsrf({
    url,
    callbackUrl: currentUrl,
    idpHint,
    login_type,
  });
  if (data.url) {
    window.location.href = data.url;
  } else {
    throw new Error('No url found');
  }
};

export const signOut = async () => {
  const currentUrl = window.location.href;
  const url = `${PORTAL_URL}/api/auth/signout/keycloak`;
  clearCachedKey();
  Cookies.set(WAS_SIGNED_IN_COOKIE_NAME, '0');
  await postWithCsrf({ url, callbackUrl: SIGN_OUT_CALLBACK });
  window.location.href = `https://${AUTH_SERVER_DOMAIN}/auth/realms/${AUTH_SERVER_REALM}/protocol/openid-connect/logout?redirect_uri=${currentUrl}`;
};

export const useSession = () => {
  const cookie = Cookies.get(AUTH_SESSION_COOKIE_NAME);
  const [authState, setAuthState] = useState<{
    token: string | null;
    isSignedIn: boolean;
    isLoading: boolean;
    user?: User;
  }>({
    token: null,
    isSignedIn: false,
    isLoading: true,
    user: null,
  });

  useEffect(() => {
    const onBeforeUnload = () => {
      sessionStorage.setItem('scrollPosition', window.scrollY.toString());
    };

    const onLoad = () => {
      const scrollPosition = sessionStorage.getItem('scrollPosition');
      if (scrollPosition) {
        window.scrollTo(0, parseFloat(scrollPosition));
        sessionStorage.removeItem('scrollPosition');
      }
    };

    // Save scroll position on redirect
    window.addEventListener('beforeunload', onBeforeUnload);

    //Retrieve and set scroll position on redirected page
    window.addEventListener('load', onLoad);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
      window.removeEventListener('load', onLoad);
    };
  }, []);

  useEffect(() => {
    function setState(token: string) {
      const decodedJSON = jwtDecode(token);
      const user = decodedJSON?.payload;
      setAuthState({
        token: token,
        isSignedIn: true,
        isLoading: false,
        user: user ? { ...user, id: user.sub } : null,
      });
      const wasSignedIn = Cookies.get(WAS_SIGNED_IN_COOKIE_NAME);
      // send user login event to google analytics when user first logs in
      if (wasSignedIn === '0' && window.dataLayer) {
        const gaData = {
          event: 'login',
          identityProvider: user?.identityProvider,
          userId: user?.sub,
        };
        window.dataLayer.push(gaData);
        analyticsLogger('GA', 'dataLayer', JSON.stringify(gaData, null, 2));
      }
      Cookies.set(WAS_SIGNED_IN_COOKIE_NAME, '1');
    }
    if (cookie) {
      setState(cookie);
      return;
    }
    const getSession = async () => {
      const url = `${PORTAL_URL}/api/auth/session`;

      try {
        const data: { encodedToken: string } = await fetch(url, {
          method: 'GET',
          credentials: 'include',
        }).then((res) => res.json());

        if (data.encodedToken) {
          setState(data.encodedToken);
        } else {
          throw new Error('No token found');
        }
      } catch (e) {
        Cookies.set(WAS_SIGNED_IN_COOKIE_NAME, '0');
        setAuthState({
          token: null,
          isSignedIn: false,
          isLoading: false,
          user: null,
        });
      }
    };
    getSession();
  }, [cookie]);

  return authState;
};
