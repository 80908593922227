const WEB = 'web';
const IOS = 'ios';
const ANDROID = 'android';
const XAMARIN = 'xamarin';
const UWP = 'uwp';
const LINUX = 'linux';
const WINDOWS = 'windows';
const MAC = 'mac';
const CLI = 'cli';
const CORE = 'core';
const DOTNET = 'dotnet';
const DOTNETCORE = 'dotnetcore';
const NODEJS = 'nodejs';
const JAVA = 'java';
const CPP = 'cpp';
const PHP = 'php';
const PYTHON = 'python';
const RUBY = 'ruby';
const GO = 'go';
const SALESFORCE = 'salesforce';
const APPIAN = 'appian';
const MENDIX = 'mendix';
const OUTSYSTEMS = 'outsystems';
const SHAREPOINT = 'sharepoint';
const CROSS_PLATFORM = 'cross-platform';

module.exports = {
  WEB, IOS, ANDROID, XAMARIN, UWP, LINUX, WINDOWS, MAC, CLI, CORE,
  DOTNET, DOTNETCORE, NODEJS, JAVA, CPP, PHP, PYTHON, RUBY, GO, 
  SALESFORCE, APPIAN, MENDIX, OUTSYSTEMS, SHAREPOINT, CROSS_PLATFORM
};


module.exports.OFFICIAL_PLATFORM_LIST = [
  WEB, IOS, ANDROID, XAMARIN, UWP, LINUX, WINDOWS, MAC, CLI, CORE,
  DOTNET, DOTNETCORE, NODEJS, JAVA, CPP, PHP, PYTHON, RUBY, GO, 
  SALESFORCE, APPIAN, MENDIX, OUTSYSTEMS, SHAREPOINT,
  CROSS_PLATFORM
];

module.exports.OFFICIAL_PLATFORM_MAP = {
  WEB, IOS, ANDROID, XAMARIN, UWP, LINUX, WINDOWS, MAC, CLI, CORE,
  DOTNET, DOTNETCORE, NODEJS, JAVA, CPP, PHP, PYTHON, RUBY, GO, 
  SALESFORCE, APPIAN, MENDIX, OUTSYSTEMS, SHAREPOINT, CROSS_PLATFORM
};

module.exports.CORE_PLATFORM_LIST = [
  LINUX, WINDOWS, MAC, CLI, DOTNET, DOTNETCORE, NODEJS, JAVA, 
  CPP, PHP, PYTHON, RUBY, GO, SALESFORCE, APPIAN, OUTSYSTEMS,
  LINUX, WINDOWS, MAC, CLI, SALESFORCE, APPIAN, OUTSYSTEMS, CROSS_PLATFORM
]

module.exports.PROD_FIRST_CORE_LANGUAGE_PLATFORMS = [
  "dotnet", "dotnetcore", "java", "cpp", "php",'python2', 'python3', "python", "ruby", "go", "nodejs", 
];
module.exports.PROD_FIRST_CORE_PLATFORMS = ["mac", "windows", "linux"];
module.exports.NO_REDIRECT_ROUTES = ["/changelog/", "/faq/", "/samples/", "/api/", "/download/"];




const IN = 'in';
const ON = 'on';
const USING = 'using';

module.exports.IN_ON_MAP = {
  [WEB]: USING,
  [ANDROID]: IN,
  [IOS]: ON,
  [XAMARIN]: IN,
  [MAC]: ON,
  [WINDOWS]: ON,
  [UWP]: IN,
  [LINUX]: ON,
  [CLI]: IN,
  [CORE]: ON,
  [DOTNET]: IN,
  [DOTNETCORE]: IN,
  [NODEJS]: IN,
  [JAVA]: IN,
  [CPP]: IN,
  [PHP]: IN,
  [PYTHON]: IN,
  [RUBY]: IN,
  [GO]: IN,
  [SALESFORCE]: IN,
  [APPIAN]: IN,
  [MENDIX]: IN,
  [OUTSYSTEMS]: IN,
  [SHAREPOINT]: IN
}

module.exports.PLATFORM_LANGUAGES = {
  [ANDROID]: ['java', 'kotlin', 'kt'],
  [IOS]: ['swift', 'objectivec'],
  [WEB]: ['javascript', 'js', 'ES5', 'ES6+', '6.0js', '7.0js', '8.0js', '10.2js', '5.1html', '8.0html', '10.5js', '11.0js', 'typescript', 'scss', 'css'],
  [XAMARIN]: ['csharp', 'cs'],
  [MAC]: ['cs', 'cpp', 'java', 'dotnetcore.cs', 'swift', 'objectivec', 'php', 'python', 'ruby', 'javascript'],
  [WINDOWS]: ['cs', 'cpp', 'csharp', 'java', 'dotnetcore.cs', 'python', 'vb', 'javascript', 'go'],
  [UWP]: ['uwp.cs', 'csharp', 'cs'],
  [LINUX]: ['cs', 'cpp', 'java', 'dotnetcore.cs', 'php', 'python', 'ruby', 'javascript', 'go'],
  [CLI]: [LINUX, MAC, WINDOWS],
  [CORE]: ['cs', 'cpp', 'javascript', 'java', 'kotlin', 'kt', 'objectivec', 'swift', 'php', 'python', 'ruby', 'vb', 'go'],
  [DOTNET]: ['cs', 'vb'],
  [DOTNETCORE]: ['dotnetcore.cs', 'cs', 'vb'],
  [JAVA]: ['java'],
  [CPP]: ['cpp'],
  [PHP]: ['php'],
  [PYTHON]: ['python'],
  [RUBY]: ['ruby'],
  [GO]: ['go'],
  [NODEJS]: ['javascript', 'js'],
  [SALESFORCE]: ['javascript', 'js', 'ES5', 'ES6+', '6.0js', '7.0js', '8.0js', '5.1html', '8.0html', 'typescript', 'scss', 'css'],
  [APPIAN]: ['javascript', 'js', 'ES5', 'ES6+', '6.0js', '7.0js', '8.0js','java'],
  [MENDIX]: ['javascript', 'js', 'ES5', 'ES6+', '6.0js', '7.0js', '8.0js','java'],
  [OUTSYSTEMS]: ['javascript', 'js', 'ES5', 'ES6+', '6.0js', '7.0js', '8.0js','java'],
  [SHAREPOINT]: ['javascript', 'js', 'ES5', 'ES6+', '6.0js', '7.0js', '8.0js','java']
};


module.exports.PLATFORM_FRAMEWORKS = {
  "web": [
    "React",
    "Angular",
    "Vue",
    "Next.js",
    "NuxtJS",
    "Svelte",
    "Electron",
    "Blazor",
  ],
  
  "mobile": [
    "React Native",
    "Xamarin",
    "Flutter",
  ],
  
  "server": [
    "Node.js",
    ".NET",
    ".NET Core",
  ],
  
  "lowCode": [
    "Salesforce",
    "Appian",
    "Mendix",
    "SharePoint",
    "OutSystems",
  ],
  
  "preBuilt": [
    "Fluent",
  ],
}