import React, { ReactNode, useMemo } from 'react';
import Helmet from 'react-helmet';
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from 'gatsby';

// References:
// - https://www.hallaminternet.com/add-article-schema-markup-to-blog-posts/
// - https://schema.org/BlogPosting

type SEOProps = {
  title?: string;
  description?: string;
  canonical?: string;
  author?: string;
  isBlog?: boolean;
  urlOfPage?: string;
  headline?: string;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
  datePublished?: Date;
  dateModified?: Date;
  twitterCard?: string;
  twitterSite?: string;
  siteName?: string;
  type?: string;
  articleDescription?: string;
  url?: string;
  other?: ReactNode
}

export default function SEO({
  title,
  description,
  canonical,
  other,
  isBlog,
  author,
  urlOfPage,
  headline,
  image,
  imageWidth,
  imageHeight,
  datePublished,
  dateModified,
  articleDescription,
  twitterCard,
  twitterSite,
  siteName,
  type,
  url
}: SEOProps) {

  const { pathname } = useLocation()
  // list of paths from robots.txt that shouldn't be indexed
  const noIndexData = useStaticQuery(graphql`
    query NoindexList {
      noIndexRobots {
        data
      }
    }
  `)
  const isNoIndex = useMemo(() => noIndexData.noIndexRobots.data.some(item => {
    return pathname.indexOf(item) === 0
  }), [pathname])

  return (
    <Helmet>
      {
        title &&
        <title key="title">{title}</title>
      }
      
      {
        (headline || title) &&
        <meta property='og:title' content={headline || title} />
      }

      {
        (articleDescription || description) &&
        <meta property='og:description' content={articleDescription || description} />
      }
      
      {
        image &&
        <meta property='og:image' content={image} />
      }
      
      {
        description &&
        <meta name='description' content={description} />
      }

      {
        canonical &&
        <link rel='canonical' href={`${canonical + (canonical.endsWith('/') ? '' : '/')}`} />
      }

      {
        isNoIndex && <meta name="robots" content="noindex" />
      }

      {
        twitterCard  && <meta name="twitter:card" content={twitterCard} />
      }

      {
        twitterSite && <meta name="twitter:site" content={twitterSite} />
      }

      {
        siteName && <meta property="og:site_name" content={siteName} />
      }

      {
        type && <meta property="og:type" content={type} />
      }

      {
        url && <meta property="og:url" content={url} />
      }

      {other}
    </Helmet>
  )
}