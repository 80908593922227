

export const sendAlert = (text) => {
  const element = document.getElementById('alert');

  element.innerHTML = `<p>${text}</p>`;
  element.className = 'visible';

  setTimeout(() => {
    element.className = '';
  }, 4000)
}

const barKey = '@pdftron_alert_seen';

export const hideBar = () => {
  if (typeof window === 'undefined') return;
  sessionStorage.setItem(barKey, true);
}

export const canShowBar = (date) => {
  if (typeof window === 'undefined') return false;
  if (date < Date.now()) return false
  return !sessionStorage.getItem(barKey);
}