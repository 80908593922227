
/**
 * A function to memoize a simple string in, string out function
 */
export const simpleMemoizeFunction = (callback) => {

  const cache = {};

  return (...params: any[]) => {
    const cacheKey = params.reduce((acc, item) => acc + `-${item}`, '');
    const cached = cache[cacheKey]
    if (cached) return cached;

    const result = callback(...params);
    cache[cacheKey] = result
    return result;
  }

}