import { useRef } from "react"

type CopyFunc = () => Record<any, any>

/**
 * Injects copy into a component. Accepts a path to the copy function, relative to 'src/copy'
 * 
 * @example
 * 
 * import _copy from '../copy/somefile'
 * 
 * const AboutUs = () => {
 *   const copy = useCopy(_copy);
 *   return () => <div />
 * }
 */
export default <T extends CopyFunc>(copyFunc: T): ReturnType<T> => {
  const copy = useRef(copyFunc())
  return copy.current;
}