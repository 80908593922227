import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

export default chakra(function ChevronIcon({
  className,
  fill = "#0206A8"
}) {
  return (
    <Icon className={className} fill={fill} viewBox='0 0 16 16'>
      <path fillRule="evenodd" clipRule="evenodd" d="M4 5L8 11L12 5L4 5Z"/>
    </Icon> 
  )
})