import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

type FacebookIconprops = {
  className?: string
  width?: string
  height?: string
  fill?: string
}

export default chakra(function FacebookIcon({
  className,
  width,
  height,
  fill
}: FacebookIconprops) {
  return (
    <Icon className={className} viewBox="0 0 10 18" width={width || '163'} height={height || '18'} fill={fill || 'pdftron'}>
      <title>Facebook logo</title>
      <path d="M6.39703 17.9979V9.80188H9.16203L9.57303 6.59288H6.39703V4.54888C6.39703 3.62288 6.65503 2.98888 7.98403 2.98888H9.66803V0.127882C8.84903 0.0398825 8.02503 -0.00211762 7.20103 0.000882385C4.75703 0.000882385 3.07903 1.49288 3.07903 4.23188V6.58688H0.332031V9.79588H3.08503V17.9979H6.39703Z" />

    </Icon> 
  )
})
/**
<svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.39703 17.9979V9.80188H9.16203L9.57303 6.59288H6.39703V4.54888C6.39703 3.62288 6.65503 2.98888 7.98403 2.98888H9.66803V0.127882C8.84903 0.0398825 8.02503 -0.00211762 7.20103 0.000882385C4.75703 0.000882385 3.07903 1.49288 3.07903 4.23188V6.58688H0.332031V9.79588H3.08503V17.9979H6.39703Z" fill="#868E96"/>
</svg>

 
 */