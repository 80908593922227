import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

type DiscordIconProps = {
  className?: string;
  width?: string;
  height?: string;
  color?: string;
};

export default chakra(function DiscordIcon({ className, width, height, color }: DiscordIconProps) {
  return (
    <Icon className={className} viewBox='0 0 24 24' width={width} height={height} color={color || 'white'}>
      <title>Discord Logo</title>
      <path
        d='M9.59302 10.9709C9.05102 10.9709 8.62402 11.4459 8.62402 12.0259C8.62402 12.6039 9.06102 13.0809 9.59302 13.0809C10.134 13.0809 10.561 12.6039 10.561 12.0259C10.572 11.4449 10.134 10.9709 9.59302 10.9709ZM13.061 10.9709C12.519 10.9709 12.092 11.4459 12.092 12.0259C12.092 12.6039 12.529 13.0809 13.061 13.0809C13.602 13.0809 14.029 12.6039 14.029 12.0259C14.028 11.4449 13.602 10.9709 13.061 10.9709Z'
        fill='currentColor'
      />
      <path
        d='M17.678 3H4.947C3.874 3 3 3.874 3 4.957V17.801C3 18.884 3.874 19.758 4.947 19.758H15.72L15.215 17.999L16.432 19.13L17.581 20.194L19.625 22V4.957C19.625 3.874 18.751 3 17.678 3ZM14.01 15.407C14.01 15.407 13.668 14.999 13.384 14.636C14.628 14.284 15.103 13.506 15.103 13.506C14.713 13.762 14.343 13.944 14.01 14.068C13.535 14.266 13.08 14.399 12.633 14.476C11.721 14.646 10.885 14.599 10.172 14.466C9.631 14.361 9.166 14.209 8.776 14.056C8.558 13.971 8.319 13.868 8.083 13.735C8.054 13.714 8.026 13.706 7.998 13.687C7.978 13.677 7.969 13.668 7.959 13.657C7.788 13.563 7.693 13.497 7.693 13.497C7.693 13.497 8.149 14.257 9.356 14.618C9.071 14.978 8.719 15.407 8.719 15.407C6.62 15.34 5.823 13.963 5.823 13.963C5.823 10.904 7.191 8.425 7.191 8.425C8.559 7.398 9.86 7.427 9.86 7.427L9.955 7.541C8.245 8.036 7.456 8.786 7.456 8.786C7.456 8.786 7.666 8.672 8.017 8.511C9.033 8.065 9.84 7.941 10.173 7.912C10.23 7.903 10.278 7.893 10.335 7.893C10.915 7.817 11.57 7.798 12.253 7.874C13.156 7.979 14.126 8.245 15.113 8.786C15.113 8.786 14.362 8.074 12.747 7.58L12.88 7.428C12.88 7.428 14.182 7.399 15.549 8.426C15.549 8.426 16.917 10.905 16.917 13.964C16.917 13.963 16.11 15.34 14.01 15.407Z'
        fill='currentColor'
      />
    </Icon>
  );
});
