import mixpanel from 'mixpanel-browser';
import { MixpanelEventName, MixpanelEvents, UtmsType, PrefixedUtmsType, accepted_mixpanel_utms } from './types';
import { analyticsLogger } from '../logger';

const MIXPANEL_TOKEN = process.env.GATSBY_MIXPANEL_TOKEN;
const MIXPANEL_API_HOST = process.env.GATSBY_PORTAL_URL;
mixpanel.init(MIXPANEL_TOKEN as string, {
  api_host: `${MIXPANEL_API_HOST}/api/mp`,
  ip: true,
});

function parseUtmsParams(utms: Partial<UtmsType> & Record<string, string>): Partial<UtmsType> {
  const result: Partial<UtmsType> = {};

  for (const key of accepted_mixpanel_utms) {
    if (utms[key]) {
      result[key] = utms[key];
    }
  }
  return result;
}

function prefixUtms(utms: Partial<UtmsType>): Partial<PrefixedUtmsType> {
  const result: Partial<PrefixedUtmsType> = {};
  const parsedUtms = parseUtmsParams(utms);
  for (const key of Object.keys(parsedUtms)) {
    result[`initial_${key}`] = utms[key];
  }
  return result;
}


export type MixpanelUserProperties = {
  $email: string;
  $name: string;
  country: string;
};

export const Mixpanel = {
  track: (name: MixpanelEventName, props?: MixpanelEvents[MixpanelEventName]) => {
    analyticsLogger('Mixpanel', 'track', `${name} - ${JSON.stringify(props, null, 2)}`);
    mixpanel.track(name, props);
  },
  identity: (id: string) => {
    analyticsLogger('Mixpanel', 'identify', id);
    mixpanel.identify(id);
  },
  registerSuperProperties: (props: Partial<UtmsType> & Record<string, string>) => {
    const parsedUtms = parseUtmsParams(props);
    analyticsLogger('Mixpanel', 'registerSuperProperties', JSON.stringify(parsedUtms, null, 2));
    mixpanel.register(parsedUtms);
  },
  people: {
    set: (props: Partial<MixpanelUserProperties>) => {
      analyticsLogger('Mixpanel', 'people.set', JSON.stringify(props, null, 2));
      mixpanel.people.set(props);
    },
    setUtmParams: (props: Partial<UtmsType> & Record<string, string>) => {
      const prefixedUtms = prefixUtms(props);
      analyticsLogger('Mixpanel', 'set UTM params with people.union', JSON.stringify(prefixedUtms, null, 2));
      mixpanel.people.union(prefixedUtms);
    },
    getIdentity: () => {
      return mixpanel.identify();
    }
  },
};