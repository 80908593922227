export type MixpanelEventName = 'Download click' | 'Try for free click' | 'License Key Created';

type PageLocation = 'Marketing' | 'Documentation' | 'Documentation header' | 'Get started' | 'Developer nav';

export type LicenseKeyCreatedProps = {
  Platform: 'wv' | 'server';
  'License Key': string;
  Type: 'Trial';
};

export type MixpanelEvents = {
  'Download click': MixpanelEventProps;
  'Try for free click': { Location: PageLocation };
  'License Key Created': LicenseKeyCreatedProps;
}

export type MixpanelEventProps = {
  Location: 'Marketing' | 'Documentation' | 'Documentation header' | 'Get started' | 'Developer nav';
  Version?: string;
  Platform?: string;
  SDK?: string;
};

export const accepted_mixpanel_utms = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
] as const;

export type UtmsType = Record<(typeof accepted_mixpanel_utms)[number], string>;

export type PrefixedUtmsType = {
  [K in keyof UtmsType as `initial_${K}`]: UtmsType[K];
};
