import { OFFICIAL_PLATFORM_MAP } from '../../config/platforms';
import { Mixpanel } from '../util/mixpanel/mixpanel.client';
const GRAPHQL_SERVER_URL = process.env.GATSBY_GRAPHQL_SERVER_URL;
const { WEB, WINDOWS } = OFFICIAL_PLATFORM_MAP;
const LOCAL_STORAGE_KEY = `@apryse/pws-trial-key`;
export const LOCAL_STORAGE_REVEAL_BEFORE_SIGN_IN_PLATFORM = `@apryse/reveal-before-sign-in-platform`;

interface LocalStorageKeyParam {
  platform: string;
}

export type GetDemoProjectsByUserRes = {
  data: {
    demoProjectsByUser: Array<{
      apiKeys: Array<{ value: string; platform?: string | null }>;
    }>;
  };
};

export const getApiKeyPlatformType = (platform: string) => (platform === WEB ? 'wv' : 'server');

export const getLocalStorageKey = ({ platform }: LocalStorageKeyParam) => {
  const apiKeyPlatformType = getApiKeyPlatformType(platform);
  return `${LOCAL_STORAGE_KEY}/${apiKeyPlatformType}`;
};

export const FAKE_KEY = 'demo:123456789123456789123456789123456789';

type GetKeyOptions = {
  platform: string;
};

/**
 * Gets a trial key from the localstorage if it exists
 * DOES NOT FETCH FROM SERVER. Used to hydrate initial state
 */
export const getInitialKey = ({ platform }: GetKeyOptions) => {
  if (typeof window === 'undefined') return '';
  const key = localStorage.getItem(getLocalStorageKey({ platform })) || '';
  return key;
};

/**
 * Same as 'getInitialKey' but fetches a key from the server if it does not exist.
 * Cannot be called server side
 */
export const getKey = async ({ platform }: GetKeyOptions): Promise<string> => {
  const cachedKey = localStorage.getItem(getLocalStorageKey({ platform }));
  if (cachedKey) {
    return cachedKey;
  }

  const apiKeyPlatformType = getApiKeyPlatformType(platform);
  const api = `${process.env.GATSBY_PWS_COLLECT_SERVER_URL}/api/key`;
  const queryParams = `?platform=${apiKeyPlatformType}`;
  try {
    const jsonResponse = await (await fetch(`${api}${queryParams}`, { credentials: 'include' })).json();
    if (!jsonResponse['key']) return '';
    const key = jsonResponse['key'];
    const isNewKey = jsonResponse['isNewKey'];
    if (isNewKey) {
      Mixpanel.track('License Key Created', {
        Platform: apiKeyPlatformType,
        'License Key': key,
        Type: 'Trial',
      });
    }
    localStorage.setItem(getLocalStorageKey({ platform }), key);
    return key;
  } catch (error) {
    return '';
  }
};

export const clearCachedKey = () => {
  localStorage.removeItem(getLocalStorageKey({ platform: WEB }));
  localStorage.removeItem(getLocalStorageKey({ platform: WINDOWS }));
  localStorage.removeItem(LOCAL_STORAGE_REVEAL_BEFORE_SIGN_IN_PLATFORM);
};

export const getDemoProjects = async (): Promise<GetDemoProjectsByUserRes> => {
  try {
    const res = (
      await fetch(GRAPHQL_SERVER_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
          query: `
          query GetDemoProjectsByUser {
            demoProjectsByUser {
              apiKeys {
                value
                platform
              }
            }
          }
        `
        })
      })
    ).json();
    return res;
  } catch (error) {
    throw new Error(error);
  }
};
