import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

type LinkedInIconProps = {
  className?: string
  width?: string
  height?: string
  fill?: string
}

export default chakra(function LinkedInIcon({
  className,
  width,
  height,
  fill
}: LinkedInIconProps) {
  return (
    <Icon className={className} viewBox="0 0 19 19" width={width || '163'} height={height || '18'} fill={fill || 'pdftron'}>
      <title>LinkedIn Logo</title>
      <path
        d="M2.98292 5.19802C4.19132 5.19802 5.17092 4.21842 5.17092 3.01002C5.17092 1.80162 4.19132 0.822021 2.98292 0.822021C1.77452 0.822021 0.794922 1.80162 0.794922 3.01002C0.794922 4.21842 1.77452 5.19802 2.98292 5.19802Z"
      />
      <path
        d="M7.23673 6.85591V18.9949H11.0057V12.9919C11.0057 11.4079 11.3037 9.87391 13.2677 9.87391C15.2047 9.87391 15.2287 11.6849 15.2287 13.0919V18.9959H18.9997V12.3389C18.9997 9.06891 18.2957 6.55591 14.4737 6.55591C12.6387 6.55591 11.4087 7.56291 10.9057 8.51591H10.8547V6.85591H7.23673ZM1.09473 6.85591H4.86973V18.9949H1.09473V6.85591Z"
      />
    </Icon> 
  )
})
/**
 
<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.98292 5.19802C4.19132 5.19802 5.17092 4.21842 5.17092 3.01002C5.17092 1.80162 4.19132 0.822021 2.98292 0.822021C1.77452 0.822021 0.794922 1.80162 0.794922 3.01002C0.794922 4.21842 1.77452 5.19802 2.98292 5.19802Z" fill="#868E96"/>
<path d="M7.23673 6.85591V18.9949H11.0057V12.9919C11.0057 11.4079 11.3037 9.87391 13.2677 9.87391C15.2047 9.87391 15.2287 11.6849 15.2287 13.0919V18.9959H18.9997V12.3389C18.9997 9.06891 18.2957 6.55591 14.4737 6.55591C12.6387 6.55591 11.4087 7.56291 10.9057 8.51591H10.8547V6.85591H7.23673ZM1.09473 6.85591H4.86973V18.9949H1.09473V6.85591Z" fill="#868E96"/>
</svg>

 */