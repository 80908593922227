
import { createContext } from 'react';

export interface SignInModalContextValue {
  isSignInModalOpen: boolean;
  onSignInModalOpen: () => void;
  onSignInModalClose: () => void;
}

export const initialSignInModalContextValue: SignInModalContextValue = {
  isSignInModalOpen: false,
  onSignInModalOpen: () => {
    // do nothing
  },
  onSignInModalClose: () => {
    // do nothing
  },
};

export const SignInModalContext = createContext<SignInModalContextValue>(initialSignInModalContextValue);
