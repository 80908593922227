import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

type HamburgerIconProps = {
  className?: string;
  width?: string;
  height?: string;
};

export default chakra(function DiscordIcon({ className, width, height }: HamburgerIconProps) {
  return (
    <Icon className={className} viewBox='0 0 24 24' fill="none" xmlns="http://www.w3.org/2000/svg" width={width || '24px'} height={height || '24px'}>
      <title>Hamburger Icon</title>
      <path d="M4 18L20 18" stroke="white" strokeWidth="2" strokeLinecap="round"/>
      <path d="M4 12L20 12" stroke="white" strokeWidth="2" strokeLinecap="round"/>
      <path d="M4 6L20 6" stroke="white" strokeWidth="2" strokeLinecap="round"/>
    </Icon>
  );
});