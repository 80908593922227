import { addQueryParams, urlsMatch } from './URL';
import { isLocal } from './Analytics';

import { OFFICIAL_PLATFORM_MAP } from '../../config/platforms';
import { DEFAULT_LANGUAGES } from '../../config/languages';
import { removeDocumentationFromLink } from '../util/removeDocumentationFromLink';

const IS_SERVER_RENDER = typeof window === 'undefined';

const {
  CORE
} = OFFICIAL_PLATFORM_MAP;


let localVariables = {};
export const setLocalVariable = (name, node) => {
  localVariables[name] = node;
}

export const getLocalVariable = (name) => localVariables[name] || null;
export const clearLocalVariables = () => {
  localVariables = {};
}

const CACHE_KEY = "@pdftron_doc_cache";
export const CACHE_KEYS = {
  LANGAUAGE: '@pdftron_language_cache',
  TABS: '@pdftron_tab_cache',
  GUIDE_TYPE: '@pdftron_guide_type_cache',
  GUIDE_TYPE_LANGUAGE: '@pdftron_guide_language_cache',
  GUIDE_TYPE_TAB: '@pdftron_guide_tab'
};

let PLATFORM_CACHE = {};
export const initCache = () => {
  if (!IS_SERVER_RENDER && localStorage) {
    PLATFORM_CACHE = JSON.parse(localStorage.getItem(CACHE_KEY) || "{}");
  }
}

const HOOKS = [];

export const setItem = (platform, key, value) => {
  if (!platform) platform = 'unknown';
  if (!PLATFORM_CACHE[platform]) PLATFORM_CACHE[platform] = {};
  PLATFORM_CACHE[platform][key] = value;
  HOOKS.forEach(h => h());

  if (isLocal) {
    console.log(`%cCaching ${platform}-${key} to ${value}`, 'font-size: 20px; color: blue');
  }

  if (localStorage) {
    localStorage.setItem(CACHE_KEY, JSON.stringify(PLATFORM_CACHE));
  }
}

export const getItem = (platform, key, available) => {
  if (!platform) platform = 'unknown';
  if (!PLATFORM_CACHE[platform]) PLATFORM_CACHE[platform] = {};
  const recommended = PLATFORM_CACHE[platform][key];

  if (available && available.indexOf(recommended) === -1) {

    // If they have nothing cached and they somehow selected a langauge,
    // return it here. This is an edge case
    if ((key === CACHE_KEYS.LANGAUAGE || key === CACHE_KEYS.GUIDE_TYPE) && platform === CORE) {
      const { language } = getPreferred();
      if (language) return language;
    }

    const d = DEFAULT_LANGUAGES[platform];
    if (d && available.indexOf(d) > -1) return d; 

    return available[0];
  }

  return recommended;
}

export const setHook = (func) => {
  HOOKS.push(func);
}

export const removeHook = (func) => {
  const index = HOOKS.findIndex(hook => func === hook);
  HOOKS.splice(index, 1);
}


let lastPage = {};

export const pushLastPage = (url, title) => {
  lastPage = { url, title };
}

export const getLastPage = () => lastPage.url && lastPage.title ? lastPage : null;

export const getFormattedNavLink = (link, platform, current) => {
  if (!platform) platform = 'unknown';

  link = (link || '').replace(/{platform}/g, platform);
  link = removeDocumentationFromLink(link);

  const linkRegex = /{(.*?)}/;
  let baseLink = null;
  link = link.replace(linkRegex, (f, a) => {
    const i = getItem(platform, CACHE_KEYS.GUIDE_TYPE_TAB, a.split('|'));
    baseLink = link.replace(linkRegex, '');
    return i
  });

  if (current && current.indexOf(baseLink) > -1) {
    let currentSuffix = current.split('/').pop();
    link = baseLink + currentSuffix;
  }

  link = addQueryParams(link);

  return { link, baseLink };
}

// this function looks through any subheader items and applies
// lastHeader and lastSubheader to them
export const formatNavItems = (items, chunks, initAcc = []) => {
  items = items.reduce((acc, item) => {
    if (item.chunk) {
      let itemChunks = chunks.filter(chunk => chunk.group === item.chunk);
      acc = [...acc, ...itemChunks];
      return acc;
    }

    if (item.subheader && item.links) {
      item.links = item.links.reduce((acc, item) => {
        if (item.chunk) {
          let itemChunks = chunks.filter(chunk => chunk.group === item.chunk);
          acc = [...acc, ...itemChunks];
          return acc;
        }
        acc.push(item);
        return acc;
      }, [])
    }

    acc.push(item);
    return acc;
  }, initAcc)


  let lastHeader = null;
  items = items.map(item => {

    if (!item.lastHeader) {
      item.lastHeader = lastHeader;
    }

    if (item.subheader && item.links) {
      item.links = item.links.map(link => {
        link.lastSubheader = item.subheader;
        link.lastHeader = item.lastHeader;
        return addQueryParams(link);
      });
    }
    
   
    if (item.header) {
      lastHeader = item.header;
    }
    return item;
  });

  return items;
}

export const getActiveNavItemData = (items, platform, pathname) => {
  // compile ignore header list
  const ignoreHeader = [...items].reduce((acc, item) => {
    if ((!!item.header || !!item.subheader) && item[platform] === 'ignore') {
      acc[item.subheader || item.header] = true;
    }
    return acc;
  }, {})

  // flatten the item array so we can find deeply nested items as well
  items = [...items].reduce((acc, item) => {
    acc.push(item);
    if (item.links) {
      acc = [...acc, ...item.links];
    }
    return acc;
  }, [])

  // filter out ignored items
  items = [...items].filter(item => {
    let link = item[platform] || item.link;
    const header = item.lastSubheader || item.lastHeader;
    return link !== 'ignore' && !ignoreHeader[header];
  })

  // try and find exact match first
  let activeNavItemIndex = items.findIndex(v => {
    if (!v.link) return false;
    let { link } = getFormattedNavLink(v.link, platform, IS_SERVER_RENDER ? null : window.location.pathname);
    
    return urlsMatch(link, pathname);
  })

  // if not found, don't show the highlighted nav item
  if (!activeNavItemIndex || activeNavItemIndex === -1) {
    activeNavItemIndex = items.findIndex(v => {
      if (!v.link) return false;
      let { link, baseLink } = getFormattedNavLink(v[platform] || v.link, platform, IS_SERVER_RENDER ? null : window.location.pathname);
      if (link.indexOf('?') > -1) {
        link = link.split('?')[0];
      }
      return urlsMatch(link, pathname, baseLink);
    }) || {};
  }

  const nextNavItem = items.slice(activeNavItemIndex+1).find(i => i.title && i.link);
  const previousNavItem = items.slice(0, activeNavItemIndex).reverse().find(i => i.title && i.link);
  
  return {
    activeNavItem: items[activeNavItemIndex],
    nextNavItem,
    previousNavItem,
  };
}

/***
Header > platform and language persist (based on URL param) // DONE
Landing directly on a platform & language specific lander or guide (ex. via google search) > persists DONE
Switching language tabs (code snippets) > persists // DONE BY DEFAULT

Switching platform tabs (ex. flex guide) > persists

Platform switcher in UI > TBD (not specified)
***/
export const setPreferred = ({ platform, language, tab }) => {
  if (platform) {
    setItem('preferred', CACHE_KEYS.GUIDE_TYPE, platform);
  }

  if (tab || platform) {
    setItem(platform, CACHE_KEYS.GUIDE_TYPE_TAB, tab || platform);
  }

  if (language) {
    setItem(platform, CACHE_KEYS.GUIDE_TYPE_LANGUAGE, language);
  }
}

export const getPreferred = (platform_key) => {
  let platform = getItem('preferred', CACHE_KEYS.GUIDE_TYPE);
  let language = getItem(platform_key, CACHE_KEYS.GUIDE_TYPE_LANGUAGE);
  let tab = getItem(platform_key, CACHE_KEYS.GUIDE_TYPE_TAB);
  return { platform, language, tab };
};

export const getPreferredLink = (link, key) => {
  let copy = decodeURIComponent(link);
  const linkRegex = /[{[](.*?)[}\]]/;
  const r = copy.replace(linkRegex, (f, a) => {
    const platform = getItem(key, CACHE_KEYS.GUIDE_TYPE_TAB, a.split('|'));
    return platform
  });

  return r;
}

const NAV_ITEM_CACHE = {
  nextNavItem: null,
  previousNavItem: null
}

export const setNavItems = ({ nextNavItem, previousNavItem }) => {
  NAV_ITEM_CACHE.nextNavItem = nextNavItem;
  NAV_ITEM_CACHE.previousNavItem = previousNavItem;
}

export const getNavItems = () => ({
  nextNavItem: NAV_ITEM_CACHE.nextNavItem,
  previousNavItem: NAV_ITEM_CACHE.previousNavItem
})

// replace the title after '|' with '| Apryse Documentation'
export const formatTitle = (title) => {
  if (!title) return 'Apryse Documentation';
  const titleArr = title.split('|');
  if (titleArr.length > 1) {
    titleArr[titleArr.length - 1] = `Apryse Documentation`;
    return titleArr.join(' | ');
  }
  return `${title} | Apryse Documentation`
}
