import {
  chakra,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { PropsWithChildren, useContext } from 'react';
import EnvelopeIcon from '../assets/inline/envelope.svg';
import GitHubIcon from '../assets/inline/github-logo.svg';
import GoogleIcon from '../assets/inline/google-logo.svg';
import { SignInModalContext } from '../context/SignInModalContext';
import { signIn, IdpHint, LoginType } from '../hooks/useAuth';
import { sendAlert } from '../services/AlertService';
import Link from './Link';

const LinkButtonWithIcon = ({
  children,
  bg,
  border,
  idpHint,
  login_type,
}: PropsWithChildren<{ idpHint?: IdpHint; bg?: string; border?: string; login_type?: LoginType }>) => (
  <HStack
    cursor='pointer'
    onClick={async() => {
      try {
        await signIn({idpHint, login_type })
      } catch(e) {
        sendAlert('There was an error signing in. Please try again later')
      }
      
    }}
    bg={bg || 'white'}
    w='full'
    mt={0}
    justifyContent='center'
    h='44px'
    borderRadius='4px'
    border={border || '1px solid #CED4DA'}
  >
    {children}
  </HStack>
);

const SignInProviderButtons = () => {
  return (
    <VStack w='full' gap={4} fontWeight='500'>
      <LinkButtonWithIcon bg='black' idpHint='github'>
        <GitHubIcon /> <Text color='white'>GitHub</Text>
      </LinkButtonWithIcon>
      <LinkButtonWithIcon idpHint='google'>
        <GoogleIcon /> <Text color='#0000008a'>Google</Text>
      </LinkButtonWithIcon>
      {/* <LinkButtonWithIcon idpHint='microsoft'>
        <MSIcon /> <Text color='#0000008a'>Microsoft</Text>
      </LinkButtonWithIcon> */}
    </VStack>
  );
};

export default chakra(function SignInModal() {
  const {  isSignInModalOpen, onSignInModalClose } = useContext(SignInModalContext)
  return (
    <>
      <Modal isOpen={isSignInModalOpen} onClose={onSignInModalClose} autoFocus={false} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent top={0} bottom={0} margin='auto'>
          <ModalHeader color='apryseIndigo' fontWeight={400} fontSize='24px' lineHeight='36px'>
            Continue with
          </ModalHeader>
          <ModalCloseButton size='sm' color='black' top='24px' right='26px' />
          <ModalBody w='full'>
            <SignInProviderButtons />
            <Text my={6} textAlign='center' fontSize='14px'>
              or
            </Text>
            <LinkButtonWithIcon login_type='email'>
              <EnvelopeIcon />{' '}
              <Text color='#0000008a' fontWeight='500'>
                Sign in with email
              </Text>
            </LinkButtonWithIcon>
          </ModalBody>
          <ModalFooter>
            <Text fontSize='14px'>
              By continuing, you agree to the{' '}
              <Link blue to='https://apryse.com/legal' fontSize='14px'>
                terms of use
              </Link>{' '}
              and{' '}
              <Link blue to='https://apryse.com/privacy' fontSize='14px' >
                privacy policy
              </Link>
            </Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
