import { useEffect, useRef, useState } from 'react';
import { useLocation } from '@reach/router'
import { isProd } from '../services/Analytics';


type DynamicScriptProps = {
  whiteList?: RegExp[],

  // Blacklist has priority over whitelist
  blackList?: RegExp[],

  run: () => void;
  name: string;

  loadOnInteraction?: boolean;
}

const interactionEvents = [
  'scroll',
  'mousemove',
  'keydown',
  'click',
  'touchstart'
]

export default function DynamicScript({
  blackList,
  whiteList,
  run,
  name,
  loadOnInteraction
}: DynamicScriptProps) {

  const [hasInteracted, setHasInteracted] = useState(false);
  const { pathname } = useLocation()
  const hasRun = useRef(false);

  useEffect(() => {
    const onInteraction = () => {
      setHasInteracted(true)
      for (const event of interactionEvents) {
        document.body.removeEventListener(event, onInteraction)
      }
    };

    for (const event of interactionEvents) {
      document.body.addEventListener(event, onInteraction)
    }

    return () => {
      for (const event of interactionEvents) {
        document.body.removeEventListener(event, onInteraction)
      }
    }
  }, [])

  useEffect(() => {
    if (hasRun.current) return;
    if (blackList) {
      const isBlackListed = blackList.some(regex => pathname.match(regex))
      if (isBlackListed && !isProd()) {
        console.log(`%cDynamicScript: Script "${name}" blacklisted`, 'color: black; font-size: 24px')
      }
      if (isBlackListed) return;
    }
  
    if (whiteList) {
      const isWhitelisted = whiteList.some(regex => pathname.match(regex))
      if (!isWhitelisted) return;
    }

    if (loadOnInteraction && !hasInteracted) return;

    if (!isProd()) {
      console.log(`%cDynamicScript: Script "${name}" was loaded`, 'color: green; font-size: 24px')
    }
    run();
    hasRun.current = true;
  }, [pathname, hasInteracted])

  return null;
}