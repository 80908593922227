const {
  OFFICIAL_PLATFORM_MAP
} = require('./platforms');

const {
  ANDROID,
  IOS,
  WEB,
  XAMARIN,
  MAC,
  WINDOWS,
  UWP,
  LINUX,
  CLI,
  DOTNET,
  DOTNETCORE,
  NODEJS,
  JAVA,
  CPP,
  PHP,
  PYTHON,
  RUBY,
  GO
} = OFFICIAL_PLATFORM_MAP;

module.exports.DEFAULT_LANGUAGES = {
  [WEB]: '8.0js'
}

module.exports.FORMATTED_LANGUAGES = {
  c: 'C',
  csharp: 'C#',
  cs: 'C#',
  cpp: 'C++',
  java: 'Java',
  objectivec: 'Obj-C',
  swift: 'Swift',
  markup: 'Markup',
  css: 'CSS',
  html: 'HTML',
  js: "JavaScript",
  javascript: 'JavaScript',
  jsx: 'React JSX',
  json: 'JSON',
  windows: 'Windows',
  mac: 'Mac',
  linux: 'Linux',
  objc: 'Objective-C',
  xml: 'XML',
  xaml: 'XAML',
  python: 'Python',
  ruby: "Ruby",
  php: "PHP",
  kotlin: "Kotlin",
  vb: "VB",
  es5: "ES5",
  "es6+": "ES6+",
  "6.0js": "v6.0+",
  "7.0js": "v7.0+",
  "8.0js": "v8.0+",
  "10.2js": "v10.2+",
  "10.5js": "v10.5+",
  "11.0js": "v11.0+",
  "5.1html": "v5.1+",
  "8.0html": "v8.0+",
  "xamarin.cs": "C# (Xamarin)",
  "dotnetcore.cs": "C# (.NET Core)",
  "android.java": "Java (Android)",
  rb: "Ruby",
  py: "Python",
  m: "Obj-C",
  kt: "Kotlin",
  "uwp.cs": "C# (UWP)",
  "node.js": "JS (Node.js)",
  "go": "Go",
  'scss': "SCSS",
  apex: 'apex',
  dart: 'Dart',
  dotNet: '.NET'
};

module.exports.LANDING_PAGES = {
  cs: '/core/guides/get-started/dotnet/',
  'dotnetcore.cs': '/core/guides/get-started/dotnetcore/',
  'uwp.cs': '/uwp/',
  'xamarin.cs': '/xamarin/',
  cpp: '/core/guides/get-started/cpp/',
  java: '/core/guides/get-started/java/',
  'android.java': '/android/',
  js: '/web/',
  'node.js': '/core/guides/?language=nodejs',
  kt: '/android/',
  m: '/ios/',
  php: '/core/guides/get-started/php/',
  py: '/core/guides/get-started/python/',
  rb: '/core/guides/get-started/ruby/',
  swift: '/ios/',
  vb: '/core/guides/get-started/dotnet/',
  go: '/core/guides/get-started/go/',
};

module.exports.LANGUAGE_PLATFORMS = {
  "cpp": [WINDOWS, "wpf", CLI, DOTNET, MAC, LINUX, CPP],
  "cs": [WINDOWS, "wpf", CLI, DOTNET, UWP, DOTNETCORE],
  "uwp.cs": [UWP],
  "xamarin.cs": [XAMARIN],
  "dotnetcore.cs": [WINDOWS, LINUX, MAC, DOTNETCORE],
  "java": ["server", MAC, LINUX, WINDOWS, JAVA],
  "android.java": [ANDROID, JAVA],
  "rb": [RUBY, LINUX, MAC],
  "go": [GO, WINDOWS, LINUX],
  "py": [CLI, MAC, LINUX, WINDOWS, PYTHON],
  "vb": [DOTNET, WINDOWS, DOTNETCORE],
  "m": [IOS, MAC],
  "swift": [IOS, MAC],
  "php": [MAC, LINUX, WINDOWS, PHP],
  "js": [WEB],
  "node.js": [NODEJS, WINDOWS, LINUX, MAC],
  "kt": [ANDROID],
  "ES5": [WEB],
  "ES6+": [WEB],
  "5.1html": [WEB],
  "8.0html": [WEB],
  "6.0js": [WEB],
  "7.0js": [WEB],
  "8.0js": [WEB],
  "10.2js": [WEB],
  "10.5js": [WEB],
  "11.0js": [WEB],
  "typescript": [WEB],
  "scss": [WEB],
  "css": [WEB]
}

module.exports.DEFAULT_PLATFORMS = {
  "cpp": CPP,
  "cs": DOTNET,
  "dotnetcore.cs": DOTNETCORE,
  "node.js": NODEJS,
  "xamarin.cs": XAMARIN,
  "java": JAVA,
  "uwp.cs": UWP,
  "android.java": ANDROID,
  "ruby": RUBY,
  "python": PYTHON,
  "rb": RUBY,
  "go": GO,
  "py": PYTHON,
  "vb": DOTNET,
  "m": IOS,
  "swift": IOS,
  "php": PHP,
  "js": WEB,
  "mm": IOS,
  "kt": ANDROID,
  "ES5": WEB,
  "ES6+": WEB,
  "5.1html": WEB,
  "8.0html": WEB,
  "6.0js": WEB,
  "7.0js": WEB,
  "8.0js": WEB,
  "10.2js": WEB,
  "10.5js": WEB,
  "11.0js": WEB,
};