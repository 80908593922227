import { Box } from "@chakra-ui/react";
import React from "react";
import _copy from "../../copy/footer/footer";
import useCopy from "../../hooks/useCopy";
import PDFTronLogoIcon from "../../icons/PDFTronLogo_140_16";
import ContentContainer from "../ContentContainer";

const Footer = React.forwardRef<HTMLDivElement>(function Footer(props, ref) {
  const copy = useCopy(_copy);

  return (
    <Box ref={ref} h="1px" zIndex="-99999" bg="blue.800">
      {/* <ContentContainer>
        <Box pt={10} px={5}>
          <PDFTronLogoIcon mb={6} />
        </Box>
      </ContentContainer> */}
    </Box>
  );
});

export default Footer;
