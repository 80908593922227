import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

type DownloadIconProps = {
  className?: string
  width?: string
  height?: string
  fill?: string;
}

export default chakra(function DownloadIcon({
  className,
  width,
  height,
  fill
}: DownloadIconProps) {
  return (
    <Icon className={className} viewBox="0 0 24 24" width={width || '24'} height={height || '24'} color={fill || "white"}>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5875 16.5833L5.66583 9.85492C5.52092 9.69291 5.48502 9.46076 5.57421 9.26255C5.66341 9.06433 5.86097 8.93724 6.07833 8.93825H8.9475V3.38325C8.9475 3.0795 9.19374 2.83325 9.4975 2.83325H14.5025C14.8063 2.83325 15.0525 3.0795 15.0525 3.38325V8.94742H17.9217C18.139 8.94641 18.3366 9.0735 18.4258 9.27171C18.515 9.46993 18.4791 9.70208 18.3342 9.86409L12.4125 16.5833C12.3081 16.7016 12.1578 16.7695 12 16.7695C11.8421 16.7695 11.6919 16.7016 11.5875 16.5833ZM3.85083 19.4983V20.7999C3.85083 21.0024 4.01499 21.1666 4.2175 21.1666H19.8008C20.0033 21.1666 20.1675 21.0024 20.1675 20.7999V19.4983C20.1675 19.2957 20.0033 19.1316 19.8008 19.1316H4.2175C4.01499 19.1316 3.85083 19.2957 3.85083 19.4983Z" fill="currentColor"/>
    </Icon> 
  )
})