import { Box, Fade } from '@chakra-ui/react';
import React, { useState } from 'react'


interface TooltipProps {
    children: React.ReactNode;
    top?: string;
    title: string;
    height?: string;
  }
  
  const Tooltip = ({children, top = '30px', title, height}: TooltipProps) => { 
    const [show, setShow] = useState(false);
    
    return (
    <Box position='relative' onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} height={height || 'auto'}>
      <Fade in={show}><Box position='absolute' zIndex={110} top={top} left='50%' style={{transform: 'translate(-50%, 0)'}} bgColor='black' color='white' px={1} py='2px' borderRadius='4px' boxShadow='2px 3px 6px 0px rgba(0,0,0,0.33)' width='max-content' maxInlineSize='400px' whiteSpace='normal'>{title}</Box></Fade>
      {children}
    </Box>
  )}


  export default Tooltip;