import {
  Box,
  chakra,
  Flex,
  Heading,
  HStack,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import React from 'react';
import ChevronIcon from '../icons/ChevronIcon';
import { DropdownOption } from './DocumentationHeader/DocumentationHeader';
import KebabHorizontal from '../icons/KebabHorizontal';

interface DropdownProps {
  selected?: string;
  onSelect(value: string, link: string): void;
  useMobileTitle?: boolean;
  className?: string;
  options?: DropdownOption[];
  forceLabel?: boolean;
}

const _Dropdown = (props: DropdownProps) => {
  return (
    <Popover direction={'ltr'}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            {props.selected ? <HStack className={props.className} justifyContent='space-between' w='full'>
              <Heading size='h6'>{props.selected}</Heading>
              <ChevronIcon fill='apryseIndigo' marginRight='4px' width='12px' transition='all 0.05s ease' />
            </HStack> : <Box cursor='pointer' ><KebabHorizontal/></Box>}
          </PopoverTrigger>
          <PopoverContent
            maxH={'50vh'}
            maxW='240px'
            overflowY={'auto'}
            fontSize={14}
            _focus={{
              boxShadow: 'xl',
            }}
          >
            {props.options.map((option, i) => {
              return (
                <Box key={i}>
                  {option.header && (
                    <Box px={4} py={2} bg={'gray.200'} fontWeight={700}>
                      {option.header}
                    </Box>
                  )}

                  {!option.header && (
                    <Link
                      _hover={{
                        textDecoration: 'none',
                      }}
                      href={option.link}
                      onClick={() => {
                        props.onSelect(option.value, option.link);
                        onClose();
                      }}
                      isExternal={option.isExternal}
                    >
                      <Flex
                        px={4}
                        py={2}
                        flexDir={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        _hover={{
                          bg: 'gray.100',
                        }}
                      >
                        <Text color='apryseIndigo' fontWeight='700'>
                          {option.title}
                        </Text>
                        {option.isExternal && <ExternalLinkIcon color='apryseIndigo' w='18px' h='18px' />}
                      </Flex>
                    </Link>
                  )}
                </Box>
              );
            })}
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

const Dropdown = chakra(_Dropdown);

export default Dropdown;
