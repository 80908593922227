import Icon from '@chakra-ui/icon';
import { chakra } from '@chakra-ui/react';
import React from 'react';

export default chakra(function SearchIcon({
  className,
  fill = "#ffffff"
}) {
  return (
    <Icon className={className} fill={fill} viewBox='0 0 24 24'>
      <path fillRule="evenodd" clipRule="evenodd" d="M16.9731 15.6211C19.5486 12.3314 19.122 7.606 15.9989 4.83082C12.8757 2.05563 8.13292 2.18758 5.16889 5.13211C2.20486 8.07664 2.04161 12.8185 4.79612 15.9599C7.55062 19.1013 12.2731 19.5591 15.5797 17.0053L19.6314 21.0569C19.7789 21.1932 20.0064 21.1932 20.1539 21.0569L21.0156 20.1861C21.085 20.1188 21.1242 20.0262 21.1242 19.9294C21.1242 19.8327 21.085 19.7401 21.0156 19.6728L16.9731 15.6211ZM12.1331 16.5378C9.57393 17.1406 6.92539 15.9728 5.64443 13.6768C4.36346 11.3808 4.76087 8.51362 6.61803 6.65257C8.4752 4.79152 11.3415 4.38812 13.6402 5.66428C15.9389 6.94045 17.1122 9.58654 16.5147 12.1469C16.0078 14.3264 14.3104 16.0309 12.1331 16.5469V16.5378Z"/>
    </Icon> 
  )
})