import { handleNavItemLinkClicked, NavItemProps } from "./Header";
import React, { createContext, useContext } from "react";

interface HeaderContext {
    onToggle?(): void;
}

export const HeaderContext = createContext<HeaderContext>({});

export const useHeaderContext = () => {
    return useContext(HeaderContext);
}

const HandleNavClickContext = React.createContext(null)

export const useHandleNavClick = () => {
  return useContext(HandleNavClickContext)
}

export const HandleNavClickProvider = ({children}) => {
  const { onToggle: onToggleNav } = useHeaderContext();
    
  const handleNavClick = (navItemProps: NavItemProps) => {
    handleNavItemLinkClicked(navItemProps);
    onToggleNav()
    navItemProps.onClose && navItemProps.onClose()
  };

  return (
    <HandleNavClickContext.Provider value={handleNavClick}>
      {children}
    </HandleNavClickContext.Provider>
  )
}