const { LANDING_PAGES, FORMATTED_LANGUAGES } = require('../config/languages');
const { OFFICIAL_PLATFORM_MAP, IN_ON_MAP  } = require('../config/platforms');
const {
  IOS,
  UWP,
  MAC,
  DOTNETCORE,
  NODEJS,
  DOTNET,
  PHP,
  CPP,
  WEB,
  CORE
} = OFFICIAL_PLATFORM_MAP

const replacementLabels = {
  [CORE]: 'Server/Desktop',
  [DOTNET]: 'C# .NET',
  [WEB]: "JavaScript",
}

module.exports.getPlatformSpellingForTitle = (spelling, useReplacementTable) => {
  const lower = (spelling || '').toLowerCase();
  
  if (useReplacementTable && replacementLabels[lower]) {
    return replacementLabels[lower];
  }

  if (lower === IOS) return 'iOS';
  if (lower === UWP) return 'UWP';
  if (lower === MAC) return 'macOS';
  if (lower === DOTNETCORE) return '.NET Core';
  if (lower === NODEJS) return 'Node.js'
  if (lower === DOTNET) return '.NET Framework';
  if (lower === PHP) return 'PHP';
  if (lower === CPP) return 'C++';
  if (spelling && spelling[0] === spelling[0].toUpperCase()) return spelling;
  return lower.charAt(0).toUpperCase() + lower.substr(1);
}

module.exports.getUserFacingLanguage = (language) => { 
  return FORMATTED_LANGUAGES[language] || language;
}

module.exports.processUserFacingString = (string, {
  language,
  platform,
  useReplacementLabels
}) => {

  if(!string) string = ''

  if (language) {
    const formattedLangauge = module.exports.getUserFacingLanguage(language);
    string = string.replace(/{{language}}/g, formattedLangauge || language);
    string = string.replace(/@@homepage/g, LANDING_PAGES[language]);
  }

  if (platform) {
    const regex = /{{platform}}/g;
    string = string.replace(
      regex,
      module.exports.getPlatformSpellingForTitle(platform, useReplacementLabels)
    );

    const inOnregex = /{{in}}/g;
    const inOnValue = IN_ON_MAP[platform];
    string = string.replace(
      inOnregex,
      inOnValue || 'in'
    );
  }

  return string;
}

