import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Box,
  Text,
  HStack,
  VStack,
  useMediaQuery,
  DrawerFooter,
} from '@chakra-ui/react';
import HamburgerIcon from '../../icons/HamburgerIcon';
import Link from '../Link';
import DiscordIcon from '../../icons/DiscordIcon';
import DownloadIcon from '../../icons/DownloadIcon';
import { NavProps, getCurrentPage, navCopy } from '../Header/Header';
import SignInButton from '../SignInButton';

export default function SideDrawer(navProps: NavProps) {
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {headerProps} = navProps;

  const currentPage = getCurrentPage(headerProps);

  const size = isLargerThan600 ? 'xs' : 'full';

  return (
    <>
      <Box
        ml='25px'
        cursor='pointer'
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
      >
        <HamburgerIcon width='24px' height='24px' />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        size={size}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color='apryseIndigo' w='24px' h='24px' />
          <DrawerHeader></DrawerHeader>

          <DrawerBody mt={2}>
            <VStack gap={4} alignItems='start'>
              {navCopy.map((item) => (
                <Link to={item.to} onClick={onClose} key={item.title}>
                  <Text color={currentPage === item.title ? 'apryseIndigo' : 'apryseNavy'}>{item.title}</Text>
                </Link>
              ))}
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <VStack gap={2} alignItems='start' w='100%'>
            <Link
                to='https://apryse.com/discord?docLink=sideDis'
                pt='10px'
                onClick={onClose}
              >
                <HStack>
                  <DiscordIcon w='24px' h='24px' color='apryseNavy' />
                  <Text color='apryseNavy'>Chat with us</Text>
                </HStack>
              </Link>
              <Link to='https://dev.apryse.com' onClick={onClose}>
                <HStack>
                  <DownloadIcon w='24px' h='24px' color='apryseNavy' />
                  <Text color='apryseNavy'>Downloads</Text>
                </HStack>
              </Link>
              <Link to='https://apryse.com/form/contact-sales?docLink=sideSal' w='100%'>
                <Button
                  bgColor='transparent'
                  border='2px solid #0206A8'
                  borderRadius='4px'
                  color='apryseNavy'
                  w='100%'
                  onClick={onClose}
                  fontWeight={500}
                >
                  Contact Sales
                </Button>
              </Link>
              <SignInButton w='100%' border='2px solid #0206A8'  />
            </VStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
