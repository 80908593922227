

/*
1. The referral URL (written on their first visit only, never overwritten)
2. The landing page URL (first visit only, never overwritten)
3. All UTM params (first visit only, never overwritten)
4. Date (first visit only, never overwritten)

utm_source
utm_medium
utm_campaign
utm_term
utm_content
*/
import Cookies from 'js-cookie';
import { getPathInfo } from './URL';
import { OFFICIAL_PLATFORM_LIST } from '../../config/platforms';
import { Mixpanel } from '../util/mixpanel/mixpanel.client';
const LONG_TERM_COOKIE_KEY = 'apryse_tracking_cookie';
const SESSION_COOKIE_KEY = 'apryse_tracking_session_cookie';
type CookieType = 'LONG_TERM' | 'SESSION';

const accepted_utm = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'gclid'
];

const getUTMParams = (search: string) => {
  if (!search) return {};
  const utms = {};

  try {
    const query = search.split('?')[1];
    const splitQueries = query.split('&');
    splitQueries.forEach(q => {
      const s = q.split('=');
      const [key, value] = s;
  
      if(accepted_utm.indexOf(key.toLowerCase()) > -1)
        utms[key.toLowerCase()] = value;
    })
    return utms;
  } catch (e) {
    return {};
  }

}


export const setLongLivedTrackingCookie = (location: Location) => {
  setTrackingCookie(location, 'LONG_TERM');
};

export const setSessionTrackingCookie = (location: Location) => {
  setTrackingCookie(location, 'SESSION');
};

const cookieOptions = (cookieType: CookieType): Cookies.CookieAttributes => ({
  expires: cookieType === 'LONG_TERM' ? 365 : 1,
  domain: process.env.NEXT_PUBLIC_ENV === 'local' ? 'localhost' : 'apryse.com',
});

const setTrackingCookie = (location: Location, cookieType: CookieType) => {
  const utmParams = getUTMParams(location.search);
  const existingLongTermCookie = Cookies.get(LONG_TERM_COOKIE_KEY);
  if (cookieType === 'LONG_TERM' && existingLongTermCookie) return;
  // only set session cookie when utm params were present
  if (cookieType === 'SESSION' && Object.keys(utmParams).length === 0) return;

  const referrer = document.referrer;
  const landingPage = location.hostname + location.pathname;
  const date = new Date().toDateString();

  const cookie = {
    referrer,
    landingPage,
    date,
    landing_date: new Date().toISOString().slice(0, 19).replace('T', ' '),
    ...utmParams,
  };

  try {
    const cookieKey =
      cookieType === 'LONG_TERM' ? LONG_TERM_COOKIE_KEY : SESSION_COOKIE_KEY;
    Mixpanel.registerSuperProperties(utmParams);
    Cookies.set(cookieKey, JSON.stringify(cookie), cookieOptions(cookieType));
  } catch (e) {
    // nothing
  }
};


export const getSessionTrackingCookie = ():  null | Record<string, any> => {
  try {
    return Cookies.get(SESSION_COOKIE_KEY);
  } catch (e) {
    return null;
  }
}

export const getLongLivedTrackingCookie = (): null | Record<string, any> => {
  try {
    return Cookies.get(LONG_TERM_COOKIE_KEY);
  } catch (e) {
    return null;
  }
}

export const setMixpanelFromCookie = () => {
  const sessionCookie = getSessionTrackingCookie();
  const longLivedCookie = getLongLivedTrackingCookie();
  if (!sessionCookie && !longLivedCookie) return;
  const cookies = { ...(longLivedCookie || {}), ...(sessionCookie || {}) };
  Mixpanel.registerSuperProperties(cookies);
  Mixpanel.people.setUtmParams(cookies);
};

const PLATFORM_KEY = '@pdftron_preferred_platforms';

export const setPreferredPlatform = (pathname) => {

  let { platform } = getPathInfo(pathname);

  if (!platform) return;

  platform = platform.split('-')[0];
  if (OFFICIAL_PLATFORM_LIST.indexOf(platform) === -1) return;

  let oldPlatforms: string | string[] = '';
  try {
    oldPlatforms = localStorage.getItem(PLATFORM_KEY) || '';
  } catch (e) {
    // do nothing
  }
  
  oldPlatforms = oldPlatforms === '' ? [] : oldPlatforms.split(',');

  if (oldPlatforms.indexOf(platform) === -1) {
    oldPlatforms.push(platform);
    try {
      localStorage.setItem(PLATFORM_KEY, oldPlatforms.join(','));
    } catch (e) {
      // do nothing
    }
  }
}

export const getPreferredPlatforms = () => {
  return localStorage.getItem(PLATFORM_KEY) || '';
}