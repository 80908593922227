import { isProd } from "./Analytics";
import { queryToObject } from "./URL";

/**
 * Backend Spec:
 * 
 * A backend must be an object with the following shape
 * 
 * {
 *    getVariant: (expId: String): Promise<Number>,
 * }
 */
// eslint-disable-next-line prefer-rest-params
function gtag() { window.dataLayer.push(arguments) }

export type ABBackend = {
  getVariant: (experimentId: string) => Promise<number>
}

export const Backends: Record<string, ABBackend> = {
  GoogleOptimizeBackend: {
    getVariant: (experimentId) => {
      console.log(`[Experiment] ${experimentId}: Getting variant...`);
      return new Promise(resolve => {
        try {
          // @ts-ignore
          gtag('event', 'optimize.callback', {
            name: experimentId,
            callback: (value) => {
              console.log(`[Experiment] ${experimentId}: Received variant ${value}`);
              resolve(Number(value));
            }
          })
        } catch {
          console.log(`[Experiment] ${experimentId}: Error getting variant - Defaulting to 0`);
          resolve(0)
        }
      })
    }
  }
} as const;

export const DEFAULT_BACKEND = Backends.GoogleOptimizeBackend;

export type ABExperiment = {
  /**
   * The variant that has been selected. Defaults to undefined
   */
  selectedVariant: number;
  /**
   * how many variants are expected (including the original)
   */
  variantCount: number;
  /**
   * a unique testing id
   */
  testingId?: number;
  /**
   * The variant IDs for each env
   */
  ids: {
    staging: string;
    production: string
  }
}

export const Experiments: Record<string, ABExperiment> = {

  /**
    V1 - remove link to licensing + remove disclaimer + restricting navigation
    V2 - V1 + Color contrast CTA (#E67F0E)
    V3 - V2 + new disclaimer
   */
  SalesFormCS_1_1: {
    selectedVariant: undefined,
    variantCount: 4,
    testingId: 1,
    ids: {
      staging: '7YBm-ByUS3eC3h1Vn2kgRQ',
      production: '2lJrzmMHSaWktVr-m_5ozA'
    }
  }
}

export const loadVariant = async (backend, experiment) => {
  const prod = isProd();
  const id = experiment.ids[prod ? 'production' : 'staging'];

  if (window.disableExperiments) {
    console.log(`[Experiment] ${id}: Experiments disabled - defaulting to 0`);
    return Promise.resolve(0);
  }

  const search = queryToObject(window.location);
  if (search.force && search.variant && search.force == experiment.testingId) {
    console.log(`[Experiment] ${id}: Forcing variant ${search.variant}`);
    return Promise.resolve(Number(search.variant));
  }

  let timeout;
  const experimentIndex = await Promise.race([
    backend.getVariant(id),
    new Promise(resolve => {
      timeout = setTimeout(() => {
        console.log(`[Experiment] ${id}: Request timed out - defaulting to variant 0`);
        resolve(0)
      }, 1500)
    })
  ])
  clearTimeout(timeout)

  return experimentIndex;
}