import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import createTheme from '@pdftron/chakra-theme';

const baseTheme = createTheme();
baseTheme.fonts = {
  heading: `'Tahoma', arial`,
  body: `'Tahoma', arial`,
}

export const smBreakpoint = '600px';
export const smdBreakpoint = '768px';
export const mdBreakpoint = '900px';
export const lgBreakpoint = '1200px';
export const xlBreakpoint = '1440px';

export default extendTheme(
  {
    breakpoints: createBreakpoints({
      sm: smBreakpoint,
      smd: smdBreakpoint,
      md: mdBreakpoint,
      lg: lgBreakpoint,
      xl: xlBreakpoint,
      '2xl': '1920px'
    }),
    colors: {
      apryseNavy: '#00083D',
      apryseLightBlue: '#00E2EA',
      apryseBabyBlue: '#BEE3F8',
      apryseIndigo: '#0206A8',
      apryseGray: "#7C8291",
      aprysePrimaryFontGray: "#111921",
      apryseSecondaryFontGray: "#4F565E",
      apryseTertiaryFontGray: "#747C84",
      apryseLightGray: "#E8EFF6",
      error: '#BF3535',
      lightBlue: '#00E7F0'
    },
    components: {
      Alert: {
        variants: {
          apryse: (props) => {
            return {
              container: {
                bg: `${props.theme.colors.apryseLightBlue}`,
                color: 'white'
              },
            }
          }
        }
      },
      Text: {
        baseStyle: {
          fontFamily: 'Tahoma, arial',
        },
      },
      Heading: {
        baseStyle: {
          fontFamily: 'Tahoma, arial',
          color: 'apryseNavy',
        },
        sizes: {
          h6: {
            fontWeight: 500,
            fontSize: '16px',
          },
        },
      },
      Radio: {
        baseStyle: {
          label: {
            marginRight: '20px',
          },
        },
      },
      Checkbox: {
        defaultProps: {
          variant: 'base',
        },
        baseStyle: {
          control: {
            _checked: {
              bg: 'pdftron',
              borderColor: 'pdftron',
              _hover: {
                bg: 'pdftron',
                borderColor: 'pdftron',
              },
            },
          },
        },
        variants: {
          base: {
            control: {
              backgroundColor: 'white',
            },
          },
        },
      },
      Button: {
        variants: {
          success: {
            backgroundColor: 'green',
          },
          yellow: {
            backgroundColor: 'yellow',
            borderRadius: '4px',
            padding: '12px 20px 12px 20px',
            _hover: {
              background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #FFD80A',
              _disabled: {
                background: 'yellow',
              },
            },
          },
          blue: {
            backgroundColor: 'pdftron',
            color: 'white',
          },
          yellowHollow: {
            borderStyle: 'solid',
            borderColor: 'yellow',
            borderWidth: '1px',
            backgroundColor: 'transparent',
            borderRadius: '54px',
            padding: '14px 30px',
            color: 'yellow',
            fontSize: 'md',
            lineHeight: '5',
          },
          blueHollow: {
            borderStyle: 'solid',
            borderColor: 'pdftron',
            borderWidth: '1px',
            backgroundColor: 'transparent',
            borderRadius: '4px',
            padding: '10px 50px',
            color: 'pdftron',
            fontSize: 'md',
            lineHeight: '4',
            _hover: {
              background:
                'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #F5F8FA',
            },
          },
          mediumHollow: {
            borderStyle: 'solid',
            borderColor: 'aliceblue.300',
            borderWidth: '1px',
            backgroundColor: 'transparent',
            borderRadius: '4px',
            padding: '10px 50px',
            color: 'grey.700',
            fontSize: 'md',
            lineHeight: '4',
            _hover: {
              background:
                'aliceblue.100',
              color: 'grey.700',
            },
            _active: {
              background:
                'aliceblue.100',
              color: 'grey.700',
            },
          },
          blueHollowTint: {
            backgroundColor: '#F5F8FA',
            borderStyle: 'solid',
            borderColor: 'pdftron',
            borderWidth: '1px',
            borderRadius: '4px',
            padding: '10px 50px',
            color: 'pdftron',
            fontSize: 'md',
            lineHeight: '4',
            _hover: {
              background: 'blue.100',
            },
            _active: {
              background: 'blue.100',
            },
          },
          darkNavy: {
            backgroundColor: '#1E3C74',
            color: 'white',
            _hover: {
              background: 'darkNavy',
            },
          },
          darkNavyHollow: {
            borderStyle: 'solid',
            borderColor: 'darkNavy',
            borderWidth: '1px',
            backgroundColor: 'transparent',
            borderRadius: '4px',
            color: 'darkNavy',
            fontSize: 'md',
            lineHeight: '4',
          },
          greyHollow: {
            borderStyle: 'solid',
            borderColor: 'blue.200',
            borderWidth: '1px',
            backgroundColor: 'transparent',
            borderRadius: '4px',
            color: 'gray.700',
            fontSize: 'md',
            lineHeight: '4',
            padding: '4px 12px',
            _hover: {
              background: 'blue.50',
              border: '1px solid',
              borderColor: 'pdftron',
              fontWeight: '600',
              color: 'pdftron',
            },
            _active: {
              background: 'blue.50',
              border: '1px solid',
              borderColor: 'pdftron',
              fontWeight: '600',
              color: 'pdftron',
            },
          },
          navHollow: {
            backgroundColor: 'transparent',
            fontweight: '400',
            _hover: {
              background: 'gray.200',
              color: 'gray.900',
            },
            _active: {
              color: 'blue.900',
              background: 'blue.100',
            },
          },
          navHollowBlue: {
            backgroundColor: 'transparent',
            fontweight: '400',
            _hover: {
              background: 'gray.200',
              color: 'pdftron',
            },
          },
          softBlue: {
            backgroundColor: '#EAF0F6',
            fontWeight: '500',
            color: '#0206A8'
          },
          reveal: {
            color: 'white',
            fontWeight: '500',
            fontSize: '16px',
            backgroundColor: 'apryseIndigo',
            borderRadius: '4px',
            padding: '12px 20px 12px 20px',
            _hover: {
              background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), #0206A8',
              _disabled: {
                background: 'apryseIndigo',
              },
            },
          },
        },
        defaultProps: {
          variant: 'blue',
        },
        baseStyle: {
          border: 'none',
          fontFamily: 'Tahoma, arial',
          fontSize: '20px',
          lineHeight: '24px',
          color: 'gray.900',
          cursor: 'pointer',
          textDecoration: 'none',
          _focus: {
            boxShadow: 'none',
          },
        },
      },
      Breadcrumb: {
        baseStyle: {
          item: {
            fontSize: 'sm',
            lineHeight: '150%',
            letterSpacing: '0.02em',
            color: 'gray.900',
            fontWeight: 'bold',
          },
        },
      },
      Link: {
        baseStyle: {
          fontSize: 'md',
          lineHeight: '150%',
          color: 'pdftron',
          _hover: {
            fontWeight: 'semibold',
            fontStyle: 'normal',
            color: 'blue.500',
          },
          _active: {
            fontWeight: 'semibold',
            fontStyle: 'normal',
            color: 'blue.500',
          },
        },
      },
      List: {
        parts: ['container', 'item', 'icon'],
        baseStyle: {
          container: {
            marginLeft: 0,
            paddingLeft: 1,
            paddingInlineStart: 4,
            listStyleType: 'none',
          },
          item: {
            marginBottom: 4,
            fontSize: 'md',
            lineHeight: '150%',
            color: 'gray.900',
          },
        },
      },
      Modal: {
        baseStyle: {
          closeButton: {
            border: 'none',
          },
        },
      },
      Popover: {
        baseStyle: {
          popper: {
            zIndex: '9999999999999 !important',
          },
          content: {
            zIndex: '9999999999999 !important',
          },
        },
      },
    },
  },
  baseTheme
);
