import { Button, chakra, Spinner, Text } from '@chakra-ui/react';
import React, { useCallback, useContext } from 'react';
import { SignInModalContext } from '../context/SignInModalContext';
import { signOut, useSession } from '../hooks/useAuth';
import { sendAlert } from '../services/AlertService';

export default chakra(function SignInButton({ className }: { className?: string }) {
  const { isSignedIn, isLoading: isAuthStateLoading } = useSession();
  const { onSignInModalOpen } = useContext(SignInModalContext);
  const handleAuthButtonClick = useCallback(async () => {
    if (isSignedIn) {
      try {
        await signOut();
      } catch(e) {
        sendAlert('There was an error signing out. Please try again later')
      }
    } else {
      onSignInModalOpen();
    }
  }, [isSignedIn]);

  return (
    <>
    <Button
      bg='white'
      ml='8px'
      w='100px'
      onClick={handleAuthButtonClick}
      isLoading={isAuthStateLoading}
      _loading={{ opacity: 1 }}
      spinner={<Spinner color='apryseIndigo' />}
      className={className}
    >
      <Text color='apryseNavy' fontWeight='500' fontSize='16px' lineHeight='24px'>
        {isSignedIn ? 'Sign Out' : 'Sign In'}
      </Text>
    </Button>
    </>
  )
})