import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Flex,
  Center,
  Text,
  Badge,
  Heading,
  useMediaQuery,
  IconButton,
} from "@chakra-ui/react";
import algoliasearch from "algoliasearch/lite";
import React, { useMemo, useRef } from "react";
import { Platforms } from "../../icons/PlatformIcon";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Stats,
  Configure,
  connectStateResults,
  Highlight,
  Snippet,
  MenuSelect,
  RefinementList,
} from "react-instantsearch-dom";
import Link from "../Link";
import "./SearchPopup.scss";
import { getPlatformSpellingForTitle, getUserFacingLanguage } from "../../../processing/text";
import { formatStringForId, getPathInfo } from "../../services/URL";
import { useSearchPopup } from "./SearchPopupContext";
import SearchIcon from "../../icons/SearchIcon";
import aa from "search-insights";
import { connectHitInsights } from 'react-instantsearch-dom';

const SearchResults = connectStateResults((props) => {
  const { searchState, searchResults, children, allSearchResults } = props;

  let showOtherFacets = false;
  if (allSearchResults) {
    const facets = allSearchResults.disjunctiveFacets;
    if (facets.length) {
      showOtherFacets = facets.some(({ data = {} }) => {
        const values = Object.values(data);
        return values.some((value) => value > 0);
      });
    }
  }

  return searchResults && searchResults.nbHits !== 0 ? (
    children
  ) : (
    <Center py={8} flexDirection="column">
      No results have been found for "{searchState.query}"
      {showOtherFacets && (
        <Center mt={4} flexDirection="column">
          <Text textAlign="center">
            However, there are results in other sections!
            <br />
            <b>Try expanding your search on the left hand side.</b>
          </Text>
        </Center>
      )}
    </Center>
  );
});

const Hit = ({ hit, insights }) => (
  <Link to={getSlugFromHit(hit)} onClick={() => {
    insights('clickedObjectIDs', {
      eventName: 'Search Result Clicked',
    });
  }} width="100%">
    <Box
      borderBottom="1px solid"
      borderColor="gray.300"
      padding="12px 12px 12px 28px"
      width="100%"
      _hover={{
        bg: "gray.100",
      }}
    >
      <Flex fontWeight={700}>
        <Box color="apryseSecondaryFontGray" flex="1">
          <Highlight hit={hit} attribute="h1" tagName="span" />
        </Box>
        <Box>
          <Badge
            fontWeight={400}
            fontSize={14}
            lineHeight="24px"
            bg="apryseBabyBlue"
            padding="0 8px"
            borderRadius={4}
            color="apryseSecondaryFontGray"
            ml={1}
          >
            {hit.platform}
          </Badge>
          <Badge
            fontWeight={400}
            fontSize={14}
            lineHeight="24px"
            bg="apryseBabyBlue"
            padding="0 8px"
            color="apryseSecondaryFontGray"
            borderRadius={4}
            ml={1}
          >
            {hit.type}
          </Badge>
        </Box>
      </Flex>
      <Box fontSize="12px" lineHeight="16px" color="apryseTertiaryFontGray">
        {hit.h2 && <Highlight hit={hit} attribute="h2" tagName="span" />}
        {hit.h3 && (
          <>
            {hit.h2 && " / "}
            <Highlight hit={hit} attribute="h3" tagName="span" />
          </>
        )}
      </Box>
      <Box fontSize="14px" lineHeight="24px">
        <Snippet hit={hit} attribute="content" tagName="span" />
      </Box>
    </Box>
  </Link>
);

const SearchRefinement = (props: SearchRefinementProps) => {
  const { defaultRefinement, attribute, label, type = "multi", transform } = props;

  const Component = type === "single" ? MenuSelect : RefinementList;

  return (
    <Box pb={4}>
      <Heading fontSize="20px" fontWeight="500" lineHeight="28px" color="aprysePrimaryFontGray">
        {label}
      </Heading>
      <Box position="relative" mt="16px">
        <Component
          attribute={attribute}
          limit={8}
          showMore
          defaultRefinement={defaultRefinement}
          transformItems={(items) =>
            items.reduce((acc, item) => {
              if (transform) {
                item = transform(item);
              }

              if (!item) return acc;
              acc.push({
                ...item,
                label: getPlatformSpellingForTitle(getUserFacingLanguage(item.label)),
              });
              return acc;
            }, [])
          }
        />
        {type === "single" && (
          <ChevronDownIcon
            fill="apryseTertiaryFontGray"
            position="absolute"
            top="0"
            bottom="0"
            right="20px"
            margin="auto"
            w="20px"
            h="20px"
            fontWeight="900"
            pointerEvents="none"
          />
        )}
      </Box>
    </Box>
  );
};


aa("init", {
  apiKey: "6288cac84879982d631a539c636f0dae",
  appId: "JWIZFLCYMI",
  partial: true,
  useCookie: true,
});

const SearchContent = (props: SearchPopupProps) => {
  const { platform, defaultSection, onClose } = props;
  const { isOpen, onOpen, onClose: onCloseRefinements } = useDisclosure();
  const [isSmallerThan900] = useMediaQuery("(max-width: 900px)");
  const searchState = useRef<{ query?: any }>({});
  const HitConnector = connectHitInsights((window as any).aa)(Hit);

  return (
    <InstantSearch
      indexName="dev_PDFTronWebsite"
      searchClient={searchClient}
      onSearchStateChange={(s) => {
        searchState.current = s;
      }}
      
    >
      <Configure clickAnalytics attributesToSnippet={["content"]} />
      <ModalContent
        h={isSmallerThan900 ? "100%" : 768}
        maxW={isSmallerThan900 ? "100%" : 1280}
        overflow="hidden"
        marginTop={isSmallerThan900 ? "0px" : "60px"}
        marginBottom={isSmallerThan900 ? "0px" : "60px"}
        borderRadius={isSmallerThan900 && "0px"}
      >
        <Flex h="100%" w={isSmallerThan900 ? "100%" : 1280} maxW="100vw" id="search-popup">
          <Box
            bg="#EAF0F7"
            minW={250}
            maxW={250}
            h="100%"
            zIndex={1000}
            position="relative"
            p={5}
            overflowY="scroll"
            transform={isSmallerThan900 && !isOpen && "translateX(-250px)"}
            transition="transform 500ms"
            className="search-refinement-container"
          >
            <SearchRefinement
              defaultRefinement={platform ? platform : undefined}
              label="Platform"
              attribute="platform"
              type="single"
              transform={(item) =>
                item.label === "blog" || item.label === "guides" ? undefined : item
              }
            />

            <SearchRefinement
              defaultRefinement={defaultSection ? defaultSection : undefined}
              label="Section"
              attribute="type"
            />

            <SearchRefinement defaultRefinement={undefined} label="Tags" attribute="tags" />
          </Box>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            boxShadow="md"
            transform={isSmallerThan900 && !isOpen && "translateX(-250px)"}
            transition="transform 500ms"
            maxW="100%"
            width="0%"
            minW={isSmallerThan900 && "100vw"}
          >
            <Box
              boxShadow="sm"
              border="1px"
              borderColor="gray.200"
              p={4}
              paddingTop={isSmallerThan900 ? "60px" : "16px"}
              maxW="100%"
            >
              <SearchBox
                autoFocus
                defaultRefinement={searchState.current.query}
                submit={
                  <>
                    <span>Search </span>
                    <SearchIcon h="16px" w="16px" />
                  </>
                }
              />
              <Stats />
            </Box>
            <Box flex="1" overflowY="scroll">
              <SearchResults>
                <Hits
                  hitComponent={HitConnector}
                />
              </SearchResults>
            </Box>
          </Box>
        </Flex>

        {isSmallerThan900 && (
          <IconButton
            icon={<HamburgerIcon />}
            aria-label="Search refinements toggle"
            bg="apryseIndigo"
            display="inline-block"
            position="fixed"
            right="16px"
            bottom="16px"
            boxShadow="md"
            onClick={() => (isOpen ? onCloseRefinements() : onOpen())}
            zIndex="1500"
          />
        )}
        {isSmallerThan900 && (
          <IconButton
            icon={<CloseIcon />}
            color="aprysePrimaryFontGray"
            aria-label="Close search modal"
            display="inline-block"
            position="fixed"
            top="0px"
            right="16px"
            onClick={onClose}
            zIndex="1500"
            variant="ghost"
          />
        )}
      </ModalContent>
    </InstantSearch>
  );
};

const SearchPopup = (props: SearchPopupProps) => {
  const { onOpen, setModalProps } = useSearchPopup();
  const { pathname } = props;
  const platform = useMemo(() => getPathInfo(pathname).platform, [pathname]);

  return (
      <Box
        onClick={(e) => {
          e.stopPropagation();
          setModalProps({ ...props, platform });
          onOpen();
        }}
        height='24px'
        width='24px'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <SearchIcon fill="white" w="24px" h="24px" />
      </Box>
  );
};

export const SearchPopupModal = () => {
  const { isOpen, onClose, modalProps } = useSearchPopup();
  const [isSmallerThan900] = useMediaQuery("(max-width: 900px)");

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={isSmallerThan900 && "full"}>
      <ModalOverlay />
      <SearchContent {...modalProps} onClose={onClose} />
    </Modal>
  );
};

SearchPopup.defaultProps = {
  placeholder: "Search",
  defaultSection: ["guide", "api", "forum"],
};

// Types
export type SearchPopupProps = {
  pathname: string;
  platform?: Platforms;
  placeholder?: string;
  defaultSection?: string[];
  hollow?: boolean;
  nav?: boolean;
  onClose?(): void;
  blog?: boolean;
  fullLength?: boolean;
};

type SearchRefinementProps = {
  defaultRefinement?: string[] | string | undefined;
  label?: string;
  attribute?: string;
  type?: string;
  transform?(item: any): string | undefined;
};

// Utilities
const searchClient = algoliasearch("JWIZFLCYMI", "6288cac84879982d631a539c636f0dae");



export const getSlugFromHit = (hit) => {
  let base = hit.slug;

  if (hit.anchor) {
    base += `#${hit.anchor}`;
    return base;
  }

  if (hit.h3) {
    base += `#${formatStringForId(hit.h3)}`;
    return base;
  }

  if (hit.h2) {
    base += `#${formatStringForId(hit.h2)}`;
    return base;
  }

  return base;
};

export default SearchPopup;
